import { YouTubeEmbed } from '@next/third-parties/google'
import {FC} from "react";

interface YouTubeVideoProps {
  attributes: any;
  videoId: string
}

export const YouTubeVideo:FC<YouTubeVideoProps> = (props) => {
  const { attributes, videoId } = props

  return (
      <div className={'embedded-youtube'}>
        <YouTubeEmbed {...attributes} videoid={videoId} style={{maxWidth: '960px'}} />
      </div>
  )
}